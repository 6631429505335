//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'

export default {
  data() {
    return {
      sm: ''    }
  },
  mounted() {
    this.$nextTick(function () {
      this.checkMobile()
    })
    window.addEventListener('resize', this.checkMobile)

    !function(){var b=function(){window.__AudioEyeSiteHash = "da280609fdef6910ab4aece8d96cfe6d"; var a=document.createElement("script");a.src="https://wsmcdn.audioeye.com/aem.js";a.type="text/javascript";a.setAttribute("async","");document.getElementsByTagName("body")[0].appendChild(a)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",b):window.attachEvent&&window.attachEvent("onload",b):b()}();
  },
  computed: {
    aboutMenu() {
      return this.$store.state.menus['about-us'] ? this.$store.state.menus['about-us'].content.links : []
    },
    resourcesMenu() {
      return this.$store.state.menus.resources ? this.$store.state.menus.resources.content.links : []
    },
    legalMenu() {
      return this.$store.state.menus.legal ? this.$store.state.menus.legal.content.links : []
    },
    socialMenu() {
      return this.$store.state.menus.social ? this.$store.state.menus.social.content.links : []
    },
    copyright() {
      return `© ${moment().format('YYYY')} Perkins LLC All rights reserved.`
    }
  },
  methods: {
    checkMobile() {
      this.sm = (window.innerWidth < 640) ? 1 : 0
    },
    scrubLink(link) {
      return link.replace('pages', '')
    }
  }
}
